import React, { useContext, useState } from "react";
import { GlobalDataContext } from "../../context/context";
import SwiperGallery from "../Gallery/SwiperGallery";
import { RiPictureInPictureExitFill } from "react-icons/ri";

function BlockGallery() {
    const { rpdata } = useContext(GlobalDataContext);
    const [showModal, setShowModal] = useState(false);
    const [imagesDB, setImagesDB] = useState([]);

    const handleClickOpen = (item) => {
        setImagesDB(item);
        setShowModal(true);
    };

    const handleClickClose = () => {
        setShowModal(false);
        setImagesDB([]);
    };

    console.log(imagesDB);

    return (
        <section className="w-full h-auto p-10 border md:flex flex-wrap gap-5 justify-center relative ">
            {rpdata?.landings?.map((item, index) => {
                const itemCount = item?.gallery ? Object.keys(item.gallery).length : 0;

                return (
                    <div key={index} className="my-[30px] shadow rounded-lg flex flex-col items-center relative">
                        <>
                            <button
                                className="bg1 absolute w-[50px] h-[60px] ml-3 cinta items-center text-center text-xl font-bold justify-center md:left-1 left-5 flex text-white rounded-b-lg"
                                onClick={() => handleClickOpen(item)}
                            >
                                <RiPictureInPictureExitFill />

                            </button>

                            <div
                                className="text-white w-[300px] mx-auto h-80 bg-slate-700 bg-cover bg-center items-center justify-center text-center flex flex-wrap gap-5 rounded-t-lg "
                                style={{ backgroundImage: `url("${item?.gallery}")` }}
                            ></div>
                            <div className="flex w-[300px] h-[130px] items-center bg1 p-4 text-white justify-between rounded-b-lg">
                                <p className="w-[60%] text-xl font-bold font-sans">{item.name}</p>
                                <p
                                    className="bg-white text-black p-2 w-[50px] text-center border border-black cursor-pointer rounded-lg shadow-lg"
                                    onClick={() => handleClickOpen(item)}

                                >
                                    {itemCount}
                                </p>
                            </div>
                        </>
                    </div>
                );
            })}

            {showModal && (
                <div
                    className="fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center"
                >
                    <div className="bg-[#2D2D2D] p-10 w-4/5 mx-auto h-[90vh] rounded-lg  overflow-y-auto">
                        <SwiperGallery
                            imagesDB={imagesDB}
                            handleClickClose={handleClickClose}
                        />
                    </div>
                </div>
            )}
        </section>
    );
}

export default BlockGallery;
